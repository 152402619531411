import React, { useEffect, useState } from 'react';
import './Grid.css';

export enum KnownAspectRatios {
    Square = 1,
    Portrait = 0.75,
    Landscape = 1.33,
    Wide = 1.78,
    /*    
    Panoramic = 2.33, */
}

export interface GridProps {
    columns?: number;
    rows?: number;

    gutterSpacing?: number;
    borderWidth?: number;
    backgroundColor?: string;

    aspectRatio?: number;

    hide?: boolean;
}

const Grid = ({
    columns = 3,
    rows = 3,
    aspectRatio = KnownAspectRatios.Landscape,
    borderWidth = 10,
    gutterSpacing = 10,
    backgroundColor = '#f5f5f5',
    hide = false,
}: GridProps) => {
    const [gridItems, setGridItems] = useState(
        Array(columns * rows).fill(null)
    );

    useEffect(() => {
        setGridItems((prevGridItems) => {
            const newGridSize = rows * columns;
            const newGridItems = Array(newGridSize).fill(null);

            // Preserve existing images
            for (let i = 0; i < newGridSize; i++) {
                if (i < prevGridItems.length) {
                    newGridItems[i] = prevGridItems[i];
                }
            }

            return newGridItems;
        });
    }, [rows, columns]);

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, index: number) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const newGridItems = [...gridItems];
                newGridItems[index] = event.target!.result;
                setGridItems(newGridItems);
            };
            reader.readAsDataURL(file);
        }
    };

    const getGridItemStyle = () => {
        return { paddingTop: getTopPaddingFromAspectRatio(aspectRatio) };
    };

    const getTopPaddingFromAspectRatio = (aspectRatio: number) => {
        return `${(1 / aspectRatio) * 100}%`;
    };

    return (
        <div>
            {hide ? null : (
                <div
                    id="grid"
                    style={{
                        padding: `${borderWidth}px`,
                        display: 'grid',
                        gridTemplateColumns: `repeat(${columns}, 1fr)`,
                        gap: `${gutterSpacing}px`,
                        backgroundColor: backgroundColor,
                    }}
                >
                    {gridItems.map((item, index) => (
                        <div
                            key={index}
                            className="grid-item"
                            onDrop={(e) => handleDrop(e, index)}
                            onDragOver={(e) => e.preventDefault()}
                            style={getGridItemStyle()}
                        >
                            {item && (
                                <img src={item} alt={`grid-item-${index}`} />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Grid;
