import React from 'react';
import './App.css';
import Grid, { GridProps, KnownAspectRatios } from './Grid';
import GridControls from './GridControls';
import { ImageGenerator } from './ImageGenerator';

function App() {
    const [gridConfiguration, setGridConfiguration] = React.useState<GridProps>(
        {
            rows: 3,
            columns: 3,
            gutterSpacing: 10,
            borderWidth: 10,
            backgroundColor: '#f5f5f5',
            aspectRatio: KnownAspectRatios.Landscape,
        }
    );

    const [isEditing, setIsEditing] = React.useState(true);

    const toggleImageEditing = async () => {
        if (isEditing) {
            // generate image from grid
        }
        setIsEditing(!isEditing);

        const grid = document.getElementById('grid');
        if (!grid) {
            return;
        }

        const outputContainer = document.getElementById('outputContainer');
        ImageGenerator.generateImage(outputContainer!, gridConfiguration, grid);

        // Reset the transformation after capturing the image
        grid.style.transform = 'scale(1)';
    };
    return (
        <div className="App">
            <header className="App-header">Image Grid Creator</header>
            <GridControls
                setGridConfiguration={setGridConfiguration}
                gridConfiguration={gridConfiguration}
            />
            <div id="outputControls" className="controls">
                <button onClick={toggleImageEditing}>
                    {isEditing ? 'Show Output Image' : 'Edit Grid'}
                </button>
            </div>
            <div id="contentContainer">
                <div
                    style={{ display: !isEditing ? 'block' : 'none' }}
                    id="outputContainer"
                />
                <Grid {...gridConfiguration} hide={!isEditing} />
            </div>
            contact @mail, ref a00b2
        </div>
    );
}

export default App;
