import { KnownAspectRatios, GridProps } from './Grid';
import React from 'react';

const GridControls = ({
    setGridConfiguration: setter,
    gridConfiguration,
}: {
    setGridConfiguration: (gridProps: GridProps) => void;
    gridConfiguration: GridProps;
}) => {
    const [isCustomAspectRatio, setIsCustomAspectRatio] = React.useState(false);

    return (
        <div id="controls" className="controls">
            <label>
                Rows:
                <input
                    onChange={(e) =>
                        setter({
                            ...gridConfiguration,
                            rows: parseInt(e.target.value),
                        })
                    }
                    value={gridConfiguration.rows}
                    type="number"
                    min="1"
                    max="10"
                />
            </label>
            <label>
                Columns:
                <input
                    onChange={(e) =>
                        setter({
                            ...gridConfiguration,
                            columns: parseInt(e.target.value),
                        })
                    }
                    type="number"
                    value={gridConfiguration.columns}
                    min="1"
                    max="10"
                />
            </label>
            <label>
                Gutter spacing (px):
                <input
                    onChange={(e) =>
                        setter({
                            ...gridConfiguration,
                            gutterSpacing: parseInt(e.target.value),
                        })
                    }
                    type="number"
                    value={gridConfiguration.gutterSpacing}
                    min="0"
                    max="50"
                />
            </label>
            <label>
                Border width (px):
                <input
                    onChange={(e) =>
                        setter({
                            ...gridConfiguration,
                            borderWidth: parseInt(e.target.value),
                        })
                    }
                    type="number"
                    value={gridConfiguration.borderWidth}
                    min="0"
                    max="50"
                />
            </label>
            <label>
                Background color:
                <input
                    onChange={(e) =>
                        setter({
                            ...gridConfiguration,
                            backgroundColor: e.target.value,
                        })
                    }
                    type="color"
                    value={gridConfiguration.backgroundColor}
                />
            </label>
            <label>
                Aspect ratio:
                <select
                    onChange={(e) => {
                        setter({
                            ...gridConfiguration,
                            aspectRatio: parseFloat(e.target.value),
                        });
                        setIsCustomAspectRatio(false);
                    }}
                    value={
                        isCustomAspectRatio
                            ? '-1'
                            : gridConfiguration.aspectRatio
                    }
                >
                    <option value={KnownAspectRatios.Portrait}>Portrait</option>
                    <option value={KnownAspectRatios.Square}>Square</option>
                    <option value={KnownAspectRatios.Landscape}>
                        Landscape
                    </option>
                    <option value={KnownAspectRatios.Wide}>Wide</option>
                    <option disabled value="-1">
                        Custom Value
                    </option>
                </select>
            </label>
            <label>
                Aspect ratio(px):
                <input
                    onChange={(e) => {
                        const targetValue = parseFloat(e.target.value);
                        if (
                            targetValue !== KnownAspectRatios.Square &&
                            targetValue !== KnownAspectRatios.Portrait &&
                            targetValue !== KnownAspectRatios.Landscape &&
                            targetValue !== KnownAspectRatios.Wide
                        ) {
                            setIsCustomAspectRatio(true);
                        } else {
                            setIsCustomAspectRatio(false);
                        }
                        setter({
                            ...gridConfiguration,
                            aspectRatio: parseFloat(e.target.value),
                        });
                    }}
                    type="number"
                    value={gridConfiguration.aspectRatio}
                    min="0.5"
                    max="3"
                    step="0.01"
                />
            </label>
        </div>
    );
};

export default GridControls;
