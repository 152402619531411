import { GridProps } from './Grid';

export class ImageGenerator {
    public static async generateImage(
        outputContainer: HTMLElement,
        gridConfiguration: GridProps,
        grid: HTMLElement
    ) {
        // Remove all children from the output container
        while (outputContainer.firstChild) {
            outputContainer.removeChild(outputContainer.firstChild);
        }

        // Calculate the output dimensions based on the grid's dimensions
        const outputWidth = grid.offsetWidth;
        const outputHeight = grid.offsetHeight;

        // Create a new canvas for the output
        const outputCanvas = document.createElement('canvas');
        outputCanvas.width = outputWidth;
        outputCanvas.height = outputHeight;
        const ctx = outputCanvas.getContext('2d');

        ctx!.fillStyle = gridConfiguration.backgroundColor!;
        ctx!.fillRect(0, 0, outputCanvas.width, outputCanvas.height);

        // Iterate over each grid item to replicate object-fit: cover
        const gridItems = grid.querySelectorAll('.grid-item'); // Adjust selector as needed
        gridItems.forEach((item) => {
            const img = item.querySelector('img');
            if (img && ctx) {
                const itemRect = item.getBoundingClientRect();
                const imgAspectRatio = img.naturalWidth / img.naturalHeight;
                const itemAspectRatio = itemRect.width / itemRect.height;

                let sx = 0,
                    sy = 0,
                    sWidth = img.naturalWidth,
                    sHeight = img.naturalHeight;

                // Adjust source rectangle to simulate object-fit: cover
                if (imgAspectRatio > itemAspectRatio) {
                    // Image is wider than the container
                    sWidth = sHeight * itemAspectRatio;
                    sx = (img.naturalWidth - sWidth) / 2;
                } else {
                    // Image is taller than the container
                    sHeight = sWidth / itemAspectRatio;
                    sy = (img.naturalHeight - sHeight) / 2;
                }

                // Calculate the position on the output canvas
                const dx = itemRect.left - grid.offsetLeft;
                const dy = itemRect.top - grid.offsetTop;
                const dWidth = itemRect.width;
                const dHeight = itemRect.height;

                // Draw the cropped image onto the canvas
                ctx.drawImage(
                    img,
                    sx,
                    sy,
                    sWidth,
                    sHeight,
                    dx,
                    dy,
                    dWidth,
                    dHeight
                );
            }
        });

        // Append the output canvas to the output container
        outputContainer.appendChild(outputCanvas);
    }
}
